<template>
  <div>
    <b-alert
      :show="resError"
      variant="secondary"
      class="text-center font-weight-bold"
    >
      目前 {{ resChoose }} 暫無資料，請重新選擇日期或資料
    </b-alert>

    <div class="borderColor mb-3">
      <ChartsAndBarItem
        ref="chartsAndBarItemRef"
        :format="datePickerFormat"
        :date-type-clicked-title="dateTypeClickedTitle"
        @bar-item-click="handleClickBarItem"
        @inv-select="handleSelectInv"
        @date-select="handleSelectDate"
      />
      <TimeSeparator
        class="m-5px"
        :date-type="dateType"
        :date-type-clicked-idx="dateTypeClickedIdx"
        @date-type-click="handleClickDateType"
      />
      <Highcharts ref="highchartsRef" :options="siteInfoDetailChartOptions" />
    </div>

    <div class="info_section">
      <!-- 案場資訊 -->
      <div class="borderColor borderColorBackground mb-3">
        <div class="info_title">{{ $t("siteInfoDetail.siteInfo") }}</div>
        <div class="d-flex">
          <div class="info_wrap">
            <div v-for="(info, key, idx) in siteInfoValue" :key="key">
              <div class="key-values" v-if="idx < 10 && idx !== 6">
                <div class="keys">{{ changeInfoLanguage(key) }}</div>
                <div class="values">
                  {{
                    urlDemo === "true"
                      ? idx === 0
                        ? urlSiteName
                        : demoContent[idx]
                      : info || "-"
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="info_wrap">
            <div v-for="(info, key, idx) in siteInfoValue" :key="key">
              <div
                class="key-values"
                v-if="(idx > 9 && idx <= 13) || idx === 17"
              >
                <div class="keys">{{ changeInfoLanguage(key) }}</div>
                <div class="values">
                  {{ urlDemo === "true" ? demoContent[idx] : info }}
                </div>
              </div>
            </div>

            <div class="key-values">
              <div class="keys line">客服 Line</div>
              <div class="values text-center">
                <img
                  src="../../assets/line_qrCode.png"
                  alt="line QR code"
                  width="120"
                  class="p-1 p-sm-2 mr-1 line_img"
                />
              </div>
            </div>
          </div>
        </div>
        <b-button class="btn_showMore" v-b-modal.site-instant-modal>
          {{ $t("siteInfoDetail.showMore") }}
        </b-button>
      </div>

      <!-- 累積發電量、今日發電量 -->
      <div class="borderColor borderColorBackground mb-3">
        <div class="info_title">
          {{ $t("siteInfoDetail.accumulativePowerGeneration") }}
        </div>
        <div class="vol-value">
          <span :class="{ surprise: isNewData, surprise1: !isNewData }">
            {{ voltages.accVal }}
          </span>
          <div>kWh</div>
        </div>
        <div class="info_title">
          {{ $t("siteInfoDetail.todayPowerGeneration") }}
        </div>
        <div class="vol-value px-5">
          <span :class="{ surprise: isNewData, surprise1: !isNewData }">
            {{ voltages.todayVal }}
          </span>
          <div>kWh</div>
        </div>
      </div>

      <!-- 即時資訊 -->
      <div class="borderColor borderColorBackground mb-3">
        <div class="info_title">
          {{ $t("siteInfoDetail.liveInfo") }}
        </div>

        <div
          class="instant_info"
          :style="{ 'grid-template-columns': !roleOthersMenu[1].hasPermission && '1fr' }"
        >
          <div class="grid_weather">
            <img class="weather_img" :src="weatherData.iconUrl" />
            <div class="weather_text">
              <div
                class="num mr-0"
                :class="{ surprise: isNewData, surprise1: !isNewData }"
              >
                {{ weatherData.temp.toFixed(1) }}
              </div>
              <div class="temperature_unit">°C</div>
            </div>
            <div class="instant_cell_text">{{ weatherData.desc }}</div>
          </div>

          <div
            v-for="(instant, instantIdx) in instantInfo"
            :key="instantIdx"
            class="instant_cell"
          >
            <div>
              <div class="instant_cell_text">
                {{ instant.title?.split(" (")[0] }}
              </div>

              <div class="num_wrap">
                <div
                  class="num"
                  :class="{ surprise: isNewData, surprise1: !isNewData }"
                >
                  {{ instant.value }}
                </div>
                <div class="unit">
                  {{ instant.title?.split(" (")[1]?.slice(0, -1) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="borderColor mb-3">
      <GmapMap
        style="height: 300px"
        :center="mapCenter"
        :zoom="9"
        :options="{ scrollwheel: false }"
      >
        <GmapMarker
          :clickable="false"
          :position="mapCenter"
          :draggable="false"
        />
        <InfoWindow
          :position="mapCenter"
          :opened="true"
          :draggable="true"
          :options="{
            content: infoWindowContent,
            pixelOffset: { height: -40, width: 0 },
          }"
        />
      </GmapMap>
    </div>

    <b-modal id="site-instant-modal" size="lg" title="案場完整資訊" ok-only>
      <b-table
        bordered
        hover
        responsive
        class="b-table-custom"
        :items="modalItems"
      >
        <template #cell(value)="data">
          <a
            target="_blank"
            :href="data.value"
            v-if="
              data.item.name.indexOf(`IP CAM`) > -1 && data.item.value !== '-'
            "
          >
            {{ data.value }}
          </a>
          <a href="#" v-else-if="data.value === 'Link'" @click="gotoScreen">
            {{ data.value }}
          </a>
          <div v-else>{{ data.value }}</div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BTable, BAlert } from 'bootstrap-vue'
import TimeSeparator from '../TimeSeparator'
import ChartsAndBarItem from './ChartsAndBarItem'
import { DateTime } from 'luxon'
import { mapState, mapMutations, mapActions } from 'vuex'
import { InfoWindow } from 'vue2-google-maps'

const infoName = [
  '案場 APP 名稱',
  '系統啟動時間',
  '發電起始日期',
  '案場編號',
  '案場座向',
  '案場區域',
  '國別',
  '縣市',
  '鄉鎮區',
  '模組種類',
  '模組數量',
  '模組斜角',
  '高壓併聯',
  '案場廠址',
  '聯絡窗口',
  '經度',
  '緯度',
  '躉售單價(元/kWh)',
  '工程保固起始日',
  '工程保固到期日',
  '預設 IRR 模組',
  '變流器',
  '案場容量',
  '總廠容量',
  '模組方位',
  '維運負責人',
  '工程師工號',
  'ACME 持有狀態',
  '電廠代號'
]
const factoryInfoKeyArray = [
  'factoryAppName',
  'systemStartupDate',
  'powerStartupDate',
  'projectNumber',
  'caseOrientation',
  'caseArea',
  'country',
  'county',
  'district',
  'moduleType',
  'moduleCounts',
  'moduleBevel',
  'highVoltageParallel',
  'caseSite',
  'contactPerson',
  'longitude',
  'latitude',
  'unitPrice',
  'warrantyStartDate',
  'warrantyDueDate',
  'presetIrrModule',
  'invModule',
  'caseCapacity',
  'caseCapacityInt',
  'moduleOrientation',
  'omPrincipal',
  'omNumber',
  'acmeStatus',
  'powerPlantTag',
  'ipCam'
]

export default {
  name: 'SiteInfoDetail',
  components: {
    BButton,
    BModal,
    BTable,
    BAlert,
    TimeSeparator,
    ChartsAndBarItem,
    InfoWindow
  },
  data () {
    return {
      dateType: [
        { title: '最近30天', format: '' },
        { title: '時歷史', format: 'yyyy-MM-dd' },
        { title: '日歷史', format: 'yyyy-MM' },
        { title: '月歷史', format: 'yyyy' },
        { title: '年歷史', format: '' },
        { title: '變流器歷史', format: 'yyyy-MM-dd' }
      ],
      dateTypeClickedIdx: 0,
      invNo: 1,
      dateValue: new Date(),
      demoCoordinate: { lat: 23.927734, lng: 121.074844 }
    }
  },
  computed: {
    ...mapState([
      'factoryData',
      'isNewData',
      'factoryInfo',
      'rolePermissionArray',
      'demoContent',
      'roleOthersMenu'
    ]),
    ...mapState('siteInfoDetail', [
      'siteInfoDetailDownloadField',
      'siteInfoDetailDownloadData',
      'voltages',
      'realTimeGrid',
      'weatherData',
      'siteInfoDetailChartOptions',
      'siteInfoValue',
      'ipCamNum',
      'uluru',
      'resError',
      'resChoose',
      'groupNumber'
    ]),
    urlFid () {
      return this.$route.query.fid
    },
    urlDemo () {
      return this.$route.query.demo
    },
    urlSiteName () {
      return this.$route.query.siteName
    },
    hasQueryDemoSite () {
      return this.$route.query.siteName.includes('展示案場')
    },
    datePickerFormat () {
      return this.dateType[this.dateTypeClickedIdx].format
    },
    dateTypeClickedTitle () {
      return this.dateType[this.dateTypeClickedIdx].title
    },
    modalItems () {
      let tempItemArray = []

      if (!this.siteInfoValue.length) {
        const siteInfoValueArray = Object.entries(this.siteInfoValue)

        if (this.hasQueryDemoSite) {
          siteInfoValueArray.forEach((x, idx) => {
            const demoValue = [
              '(展示案場)',
              '2020-01-01',
              '-',
              '12345678',
              '東西向',
              '北區',
              '台灣',
              '桃園市',
              '桃園區',
              'ABCD_0123456',
              '1234 Pcs',
              '30°',
              '外線高壓併，3相3線 11.4kV',
              '桃園市桃園區',
              '進金生能源服務',
              121.074844,
              23.927734,
              '8',
              '-',
              '-',
              '-',
              '11台',
              '一期：400Wp、二期：600kWp，共 1000kWp',
              1000,
              '南偏東12.3°',
              '進金生',
              '000123',
              'yes',
              '001',
              '-',
              'Link'
            ]

            if (x[0] === 'factoryAppName') {
              return tempItemArray.push({
                name: x[0],
                value: this.urlSiteName
              })
            }

            if (x[0] === 'ipCam') {
              return tempItemArray.push({
                name: `IP CAM1`,
                value: '-'
              })
            }

            tempItemArray.push({
              name: x[0],
              value: demoValue[idx]
            })
          })
        }

        if (!this.hasQueryDemoSite) {
          siteInfoValueArray.forEach((x) => {
            // 無新 ipCam (ipCamNum 0 or null)
            if (x[0] === 'ipCam' && !this.ipCamNum) {
              // 舊 / 無
              return x[1].forEach((imgUrl, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: imgUrl
                })
              })
            }

            // 有新 ipCam，無舊有的
            if (x[0] === 'ipCam' && this.ipCamNum && x[1].includes('-')) {
              // 直接上新的
              return [...Array(this.ipCamNum)].forEach((_item, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: `https://accountforfactories.blob.core.windows.net/live-cam/${
                    this.urlSiteName
                  }_IPCAM${idx + 1}.jpg`
                })
              })
            }

            // 有新 ipCam，有舊有的
            if (x[0] === 'ipCam' && this.ipCamNum && !x[1].includes('-')) {
              // 新 + 舊
              const newIpCamList = [...Array(this.ipCamNum)].map(
                (_item, idx) =>
                  `https://accountforfactories.blob.core.windows.net/live-cam/${
                    this.urlSiteName
                  }_IPCAM${idx + 1}.jpg`
              )
              return x[1].concat(newIpCamList).forEach((imgUrl, idx) => {
                tempItemArray.push({
                  name: `IP CAM${idx + 1}`,
                  value: imgUrl
                })
              })
            }

            tempItemArray.push({
              name: x[0],
              value: x[1] || '-'
            })
          })
        }

        tempItemArray.forEach((x) => {
          const index = factoryInfoKeyArray.findIndex(
            (y) => y === x.name && y !== 'ipCam'
          )
          if (index >= 0) {
            x.name = infoName[index]
          }
        })
      }

      let tv = {
        name: '客戶網頁',
        value: 'Link'
      }

      tempItemArray.push(tv)

      if (!this.rolePermissionArray.includes('tvscreen')) {
        // role 權限
        tempItemArray = tempItemArray.filter((x) => x.name !== '客戶網頁')
      }

      return tempItemArray
    },
    instantInfo () {
      const list = Object.keys(this.realTimeGrid).map((titleKey) => ({
        title: this.$t(`siteInfoDetail.${titleKey}`),
        value: this.realTimeGrid[titleKey]
      }))
      const filteredHasPermissionList = list.filter(({ title }) => this.roleOthersMenu[1].list.includes(title))
      return filteredHasPermissionList
    },
    mapCenter () {
      return this.urlDemo === 'true' || !this.uluru
        ? this.demoCoordinate
        : this.uluru
    },
    infoWindowContent () {
      return this.urlDemo === 'true'
        ? '展示案場'
        : this.urlSiteName === '海創一期'
          ? '海創中心'
          : this.urlSiteName === '海創二期'
            ? '海創人培中心'
            : this.urlSiteName
    }
  },
  methods: {
    ...mapMutations(['ADD_FACTORY_INFO']),
    ...mapMutations('siteInfoDetail', [
      'referenceChartOptions',
      'CLEAR_CHART_SERIES'
    ]),
    ...mapActions([
      'asyncLastSingleDoc',
      'asyncInfo',
      'updateSiteInfoDetailState'
    ]),
    ...mapActions('ChartsAndBarItem', ['setSiteInfoInvSelectOptions']),
    ...mapActions('siteInfoDetail', [
      'weather',
      'getChartByHour',
      'getChartByDay',
      'getChartByRecentThirtyDays',
      'getChartByMonth',
      'getChartByYear',
      'getChartByInvs'
    ]),
    changeInfoLanguage (str) {
      const index = factoryInfoKeyArray.indexOf(str)
      if (index >= 0) return infoName[index]
      return str
    },
    gotoScreen () {
      const systemTime = this.modalItems.find(
        (x) => x.name === '系統啟動時間'
      ).value
      const page = this.$router.resolve({
        name: 'tvscreen',
        query: {
          siteName: this.urlSiteName,
          systemTime,
          fid: this.urlFid,
          groupNumber: this.groupNumber,
          demo: this.urlDemo
        }
      })
      window.open(page.href, '_blank')
    },
    handleClickDateType (idx) {
      if (this.dateTypeClickedIdx === idx) return

      // init call api
      this.dateTypeClickedIdx = idx
      this.$refs.chartsAndBarItemRef.setToday()

      switch (this.dateTypeClickedTitle) {
        case '最近30天':
          this.getChartByRecentThirtyDays({ fId: this.urlFid })
          break
        case '時歷史':
          this.getChartByHour({
            todayDate: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '日歷史':
          this.getChartByDay({
            thisMon: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '月歷史':
          this.getChartByMonth({
            thisYear: DateTime.local().toFormat(this.datePickerFormat),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '年歷史':
          this.getChartByYear({ fId: this.urlFid, siteName: this.urlSiteName })
          break
        case '變流器歷史':
          this.getChartByInvs({
            date: DateTime.local().toFormat(this.datePickerFormat),
            invNum: this.invNo,
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
      }
    },
    handleClickBarItem (barItemObj) {
      const { chart } = this.$refs.highchartsRef
      const { title, isShow } = barItemObj
      switch (title) {
        case 'All':
          isShow
            ? chart.series.forEach((x) => x.show())
            : chart.series.forEach((x) => x.hide())
          break
        case 'kWh':
          const series = chart.series[0]
          isShow ? series.show() : series.hide()
          break
        case 'IRR':
          const series1 = chart.series[1]
          isShow ? series1.show() : series1.hide()
          break
        case 'PR':
          const series2 = chart.series[2]
          isShow ? series2.show() : series2.hide()
          break
        case '°C':
          const series3 = chart.series[3]
          isShow ? series3.show() : series3.hide()
          break
        case 'NTD':
          const series4 = chart.series[4]
          isShow ? series4.show() : series4.hide()
          break
        case 'Num':
          const refObj = {
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: isShow
                }
              }
            }
          }
          this.referenceChartOptions(refObj)
          break
      }
    },
    handleSelectDate (selectedDate) {
      // user defined date
      this.dateValue = selectedDate

      switch (this.dateTypeClickedTitle) {
        case '時歷史':
          this.getChartByHour({
            todayDate: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '日歷史':
          this.getChartByDay({
            thisMon: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '月歷史':
          this.getChartByMonth({
            thisYear: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
        case '變流器歷史':
          this.getChartByInvs({
            date: DateTime.fromJSDate(selectedDate).toFormat(
              this.datePickerFormat
            ),
            invNum: this.invNo,
            fId: this.urlFid,
            siteName: this.urlSiteName
          })
          break
      }
    },
    handleSelectInv (selectedInvNo) {
      this.invNo = selectedInvNo

      this.getChartByInvs({
        date: DateTime.fromJSDate(this.dateValue).toFormat(
          this.datePickerFormat
        ),
        invNum: this.invNo,
        fId: this.urlFid,
        siteName: this.urlSiteName
      })
    }
  },
  async created () {
    this.CLEAR_CHART_SERIES()
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.urlSiteName)
    }
    this.getChartByRecentThirtyDays({ fId: this.urlFid })
    if (this.factoryInfo.length === 0) {
      await this.asyncInfo(this.urlSiteName)
    } else {
      this.ADD_FACTORY_INFO(this.factoryInfo)
    }
    this.weather()
    this.updateSiteInfoDetailState()
    this.setSiteInfoInvSelectOptions()
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/global.scss";
// cover google map css
:deep(.gm-style-iw-chr) {
  display: none;
}
:deep(.gm-style-iw-d) {
  div {
    font-weight: 500;
    padding-right: 6px;
    padding-top: 6px;
  }
}

.info_section {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1599.98px) {
    flex-wrap: wrap;
  }

  .borderColor:nth-child(1) {
    width: 34%;

    @media screen and (max-width: 1599.98px) {
      width: 65%;
    }
    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
  }

  .borderColor:nth-child(2) {
    width: 20%;

    @media screen and (max-width: 1599.98px) {
      width: calc(35% - 1rem);
    }
    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
  }

  .borderColor:nth-child(3) {
    width: 40%;

    @media screen and (max-width: 1599.98px) {
      width: 100%;
    }
  }
}

.info_title {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.3rem;
  color: white;
  background: var(--linearButtonAndTitle);
  text-shadow: 1px 1px black;
}

.info_wrap {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.key-values {
  display: flex;
  padding: 1px 0;
  align-items: center;

  .keys {
    width: 40%;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: var(--siteinfoKeys);
    padding: 2px;
    color: $acme-blue-0;
    font-size: 17px;
  }

  .line {
    height: 124px;
    line-height: 124px;
    text-overflow: ellipsis;
  }

  .values {
    color: var(--normaltextcolor);
    font-size: 17px;
    font-weight: 700;
    margin-left: 4px;
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 0.3px;
  }
}

.borderColor {
  // 增加權重
  .btn_showMore {
    width: 100%;
    margin-bottom: -5px; // 減去 padding 下方距離
    font-size: 17px;
    font-weight: 700;
    background-color: var(--appBackground);
    color: var(--normaltextcolor);
    border: none;
    &:hover {
      color: $acme-blue-0;
    }
    // cover bootstrap btn css
    &.btn-secondary {
      &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      &:active {
        background-color: $acme-blue-0;
        &:focus {
          box-shadow: none;
          -webkit-box-shadow: none;
        }
      }
    }
  }
}

:deep(.b-table-custom) {
  .table td {
    text-align: center;
    font-weight: 700;
    padding: 7px;
    border: 2px solid rgb(255, 255, 255);
    border-left: 2px solid rgb(174, 177, 168);
  }
  tr {
    background: rgba(106, 200, 255, 0.219);
  }
  thead {
    tr {
      display: none;
    }
  }
}

.vol-value {
  @include flexCenter(center, center);
  flex-direction: column;
  height: calc(50% - 31.5px);
  min-height: 140px;
  letter-spacing: 1px;
  color: var(--normaltextcolor);
  span {
    font-size: 28px;
    font-weight: 700;
  }
  div {
    font-size: 20px;
    color: rgb(240, 128, 23);
  }
}

.line_img {
  @media screen and (max-width: 479.98px) {
    width: 100px;
  }
  @media screen and (max-width: 374.98px) {
    width: 80px;
  }
}

.instant_info {
  color: var(--normaltextcolor);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-rows: 1fr 1fr 1fr 1fr;
  // 不設定 rows 占據空間，平均分配，因有可能是 3 或 4 個 rows
  height: calc(100% - 31.5px);

  @media screen and (max-width: 1599.98px) {
    padding: 8px;
    grid-template-columns: repeat(7, 1fr);
  }
  @media screen and (max-width: 1199.98px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 991.98px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 767.98px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 5px 0px;
  }
  @media screen and (max-width: 575.98px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 479.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid_weather {
  grid-area: 1 / 1 / 3 / 2;
  margin: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #92d2e949;
  @media screen and (max-width: 479.98px) {
    grid-area: 1 / 1 / 2 / 3;
  }

  .instant_cell_text {
    color: var(--normaltextcolor);
  }
}

.instant_cell {
  margin: auto 0;
  margin-left: 15px;
  padding: 8.5px; // 每一個高會是 78.5 *4 rows 不大於 314.5(內容高度)
}

.instant_cell_text {
  font-size: 17px;
  font-weight: bold;
  color: $acme-blue-0;
}

.num_wrap {
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 767.98px) {
    flex-direction: column;
  }
}

.num {
  font-size: 28px;
  line-height: 36px;
  margin-right: 10px;
  font-weight: bold;
}

.unit {
  font-size: 12px;
}

.weather_img {
  width: 90px;
  margin-top: -20px;
  -webkit-filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.1));
  @media screen and (max-width: 479.98px) {
    width: 80px;
  }
}
.weather_text {
  margin-top: -18px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.temperature_unit {
  font-size: 20px;
  line-height: 0;
  font-weight: bold;
  margin-left: 5px;
}

@keyframes surprise {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes surprise1 {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.surprise {
  animation: surprise 1s;
}

.surprise1 {
  animation: surprise1 1s;
}
</style>
