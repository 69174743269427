<template>
  <div
    class="bar-items d-flex align-items-center justify-content-start flex-wrap flex-column flex-sm-row"
  >
    <div v-if="dateTypeClickedTitle === '變流器歷史'" class="d-flex align-items-center mx-2 mt-2 mb-1 mb-sm-2">
      <div class="mr-2">變流器:</div>
      <b-form-select
        v-model="selectedInvNo"
        :options="invSelectOptions"
        class="invSelection"
        @input="emitInvSelect"
      />
    </div>

    <datepicker
      v-if="!['最近30天', '年歷史'].includes(dateTypeClickedTitle)"
      class="inputStyle mx-2 mt-2 mb-1 mb-sm-2"
      :format="format"
      @input="emitDateSelect"
      v-model="selectedDate"
      :minimum-view="
        format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'day'
      "
      :language="zh"
      :disabled-dates="disabledDates"
    />

    <div class="d-flex align-items-center flex-wrap m-1">
      <div
        v-for="i in filterBarItems"
        :key="i.title"
        class="bar-item font-weight-bold rounded-circle"
        @click="emitBarItemClick(i.title)"
        :class="{ 'bar-item-show': i.isShow }"
      >
        {{ i.title }}
      </div>
      <div
        @click="clickDownloadCsv"
        class="bar-item bar-item-show rounded-circle m-1"
      >
        <i class="fas fa-download" />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { zh } from 'vuejs-datepicker/dist/locale'
import { mapState } from 'vuex'
import downloadCsv from 'download-csv'
export default {
  name: 'ChartsAndBarItem',
  components: {
    Datepicker
  },
  props: {
    format: {
      type: String,
      default: 'yyyy-MM'
    },
    dateTypeClickedTitle: {
      type: String,
      default: '最近30天'
    }
  },
  data () {
    return {
      zh: zh,
      disabledDates: {
        from: new Date()
      },
      barItems: ['All', 'kWh', 'IRR', 'PR', '°C', 'NTD', 'Num'].map((item) => ({
        title: item,
        isShow: true
      })),
      selectedDate: new Date(),
      selectedInvNo: 1
    }
  },
  computed: {
    ...mapState('ChartsAndBarItem', ['invSelectOptions']),
    ...mapState('siteInfoDetail', [
      'siteInfoDetailDownloadField',
      'siteInfoDetailDownloadData',
      'showPR',
      'showRevenue'
    ]),
    filterBarItems () {
      if (!this.showPR && !this.showRevenue) {
        return this.barItems.filter(
          (x) => x.title !== 'PR' && x.title !== 'NTD'
        )
      }
      if (!this.showPR) {
        return this.barItems.filter((x) => x.title !== 'PR')
      }
      if (!this.showRevenue) {
        return this.barItems.filter((x) => x.title !== 'NTD')
      }
      return this.barItems
    }
  },
  methods: {
    clickDownloadCsv () {
      let downloadData = this.siteInfoDetailDownloadData
      if (!this.showPR && !this.showRevenue) {
        downloadData = downloadData.filter(
          (obj) => obj.name !== '發電效率' && obj.name !== '發電收入'
        )
      } else if (!this.showPR) {
        downloadData = downloadData.filter((obj) => obj.name !== '發電效率')
      } else if (!this.showRevenue) {
        downloadData = downloadData.filter((obj) => obj.name !== '發電收入')
      }

      downloadCsv(
        downloadData,
        this.siteInfoDetailDownloadField,
        `案場資訊_${this.dateTypeClickedTitle}.csv`
      )
    },
    setToday () {
      this.selectedDate = new Date()
    },
    emitDateSelect () {
      this.$emit('date-select', this.selectedDate)
    },
    emitInvSelect () {
      this.$emit('inv-select', this.selectedInvNo, this.selectedDate)
    },
    emitBarItemClick (clickedBarItem) {
      if (clickedBarItem === 'All' && this.barItems[0].isShow) {
        this.barItems.forEach((item) => {
          item.isShow = false
        })
        this.$emit('bar-item-click', this.barItems[0])
      } else if (clickedBarItem === 'All' && !this.barItems[0].isShow) {
        this.barItems.forEach((item) => {
          item.isShow = true
        })
        this.$emit('bar-item-click', this.barItems[0])
      } else {
        this.barItems.forEach((item, idx) => {
          if (item.title === clickedBarItem) {
            this.barItems[idx].isShow = !this.barItems[idx].isShow
            // 接下來用 highcharts event https://codepen.io/weizhenye/pen/rrKgbP?editors=1010
            this.$emit('bar-item-click', item)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/global.scss";

.bar-items {
  background: var(--barItemBackground);
}

.bar-item {
  width: 40px;
  height: 40px;
  text-align: center;
  box-shadow: 1px 2px 2px $acme-blue-1;
  line-height: 40px;
  background: var(--barItem);
  font-size: 13px;
  margin: 0 5px;
  color: rgb(201, 199, 199);
  cursor: pointer;
  transition: 0.2s;
}

.bar-item-show {
  color: var(--normaltextcolor);
  background: var(--barItemClicked);
}
</style>
